<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0 || loading">导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :loading="loading" :columns="columns" :data-source="tableData" bordered :pagination="pagination"
          @change="handleTableChange" :scroll="{ x: 2000 }" rowKey="office_id"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mealTimeByOffice, exportMealTimeByOffice, userLog } from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "时段就餐统计",
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间(默认昨天)",
        placeholderEnd: "结束时间(默认昨天)",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      exportToExcelData: [],
      loading: false,
      columns: [], // 表格的列配置
      pagination: {
        total: 0,
        pageSize: 10, //接口返回10条数据  加上接口返回之后前端处理添加的一条合计数据总11条
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel todo对接导出接口
    export2Excel () {
      exportMealTimeByOffice(this.queryParam).then((res) => {
        util.acceptFileStream(res.data, "时段就餐统计.xls");
      });
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取分页的数据用于展示
      mealTimeByOffice(this.queryParam).then((res) => {
        let resultData = res.data.data,
            mealTimes = res.data.meal_times;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //处理接口table数据
        let formatData = [];
        resultData.data.forEach(item => {
          item.meals.forEach(it => {
            item[`all_nums_${it.meal_id}`] = it.all_nums
            item[`inst_nums_${it.meal_id}`] = it.inst_nums
            item[`system_nums_${it.meal_id}`] = it.system_nums
          })
        });
        formatData = resultData.data
        this.loading = false;
        this.tableData = formatData;
        this.tableDataLength = formatData.length;

        //配置表格列头
        let columns = [{
          title: "",
          align:'center',
          dataIndex: "empty",
          fixed: 'left',
          children:[
            {
              title: "部门",
              // ellipsis:true,
              width:175,//175就是限制10个字的长度
              align:'center',
              dataIndex: "office_title",
            }
          ]
        }];
        mealTimes.forEach(it => {
          columns.push({
            title: it.meal_title,
            children: [
              {
                title: "已报餐数",
                ellipsis:true,
                align:'center',
                dataIndex: `all_nums_${it.meal_id}`,
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'已报餐数'
                    }
                  }
                },
                customRender: (text, record, index) => {
                  if(text == undefined) {
                    return <span>0</span>
                  } else {
                    return <span>{text}</span>
                  }
                }
              },
              {
                title: "实际就餐数",
                ellipsis:true,
                align:'center',
                dataIndex: `inst_nums_${it.meal_id}`,
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'实际就餐数'
                    }
                  }
                },
                customRender: (text, record, index) => {
                  if(text == undefined) {
                    return <span>0</span>
                  } else {
                    return <span>{text}</span>
                  }
                }
              },
              {
                title: "未实际就餐数",
                ellipsis:true,
                align:'center',
                dataIndex: `system_nums_${it.meal_id}`,
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'未实际就餐数'
                    }
                  }
                },
                customRender: (text, record, index) => {
                  if(text == undefined) {
                    return <span>0</span>
                  } else {
                    return <span>{text}</span>
                  }
                }
              }
            ]
          })
        });

        this.columns = columns
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.queryParam.begin_date,
            end_date:this.queryParam.end_date,
            total:this.exportToExcelData.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>