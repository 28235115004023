<template>
  <div class="control_box_top">
    <span v-if="timeRange.show">
      <a-range-picker @change="onChangeTimeRange" :placeholder="[timeRange.placeholderStart, timeRange.placeholderEnd]"
        v-model="timeData" :allowClear="false" style="margin-right: 10px" :disabled-date="disabledDate" />
    </span>
    <span v-if="dateTime.show">
      <a-date-picker @change="onChangeDateTime" v-model="nowDate" :allowClear="false" style="margin-right: 10px" />
    </span>
    <span v-for="(item, index) in inputs" :key="item.model + index">
      <a-input :placeholder="item.placeholder" v-model="searchData.input[item.model]"
        :style="`width:${item.width}px;margin-right:10px`" />
    </span>
    <span v-for="(item, index) in selects" :key="item.model + index">
      <a-select :style="`width:${item.width}px;margin-right:10px`" v-model="searchData.select[item.model]"
        :placeholder="item.placeholder" @change="handleChange">
        <a-select-option v-for="item in item.options" :key="item.key" :value="item.key">{{ item.value }}
        </a-select-option>
      </a-select>
    </span>

    <span>
      <a-select v-if="showTypeSelect" style="width: 100px; margin-right: 10px"
        v-model="searchData.selectWithSecond.transaction_type" placeholder="类型">
        <a-select-option v-for="item in selectsWithSecond" :key="item.key" :value="item.key">{{ item.value }}
        </a-select-option>
      </a-select>
    </span>
    <span v-if="searchData.selectWithSecond.transaction_type == 1">
      <a-select style="width: 100px; margin-right: 10px" v-model="searchData.selectWithSecond.transaction_type_child"
        placeholder="全部">
        <a-select-option v-for="item in cashType" :key="item.key" :value="item.key">{{ item.value }}</a-select-option>
      </a-select>
    </span>
    <span v-if="searchData.selectWithSecond.transaction_type == 6">
      <a-select style="width: 100px; margin-right: 10px" v-model="searchData.selectWithSecond.transaction_type_child"
        placeholder="全部">
        <a-select-option v-for="item in buinessType" :key="item.key" :value="item.key">{{ item.value }}
        </a-select-option>
      </a-select>
    </span>
    <a-button type="primary" icon="search" style="margin-right: 10px" @click="search">搜索</a-button>
    <a-button icon="sync" @click="reset">刷新</a-button>
    <slot name="expand-button"></slot>
  </div>
</template>

<script>
import moment from "moment";
import util from "@/utils/util";

export default {
  name: "SearchBar",
  props: {
    inputs: {
      default: () => {
        return new Array();
      },
    },
    selects: {
      default: () => {
        return new Array();
      },
    },
    timeRange: {
      default: () => {
        return new Object();
      },
    },
    dateTime: {
      default: () => {
        return new Object();
      },
    },
    showTypeSelect: {
      default: false,
    },
  },
  data () {
    return {
      nowDate: moment(this.getCurrentData(), "YYYY-MM-DD"),
      timeData: [],
      searchData: {
        input: {},
        select: {},
        timeRange: {
          startTime: "",
          endTime: "",
        },
        selectWithSecond: {
          transaction_type_child: "",
        },
        currentDate: "",
      },
      currentSelectVal: "",
      cashType: [
        //现金充值类型
        {
          key: "",
          value: "全部",
        },
        {
          key: 1,
          value: "现金",
        },
        {
          key: 2,
          value: "微信",
        },
        {
          key: 3,
          value: "支付宝",
        },
        {
          key: 4,
          value: "银行卡",
        },
        {
          key: 5,
          value: "其他",
        },
      ],
      buinessType: [
        //商城消费类型
        {
          key: "",
          value: "全部",
        },
        {
          key: 3,
          value: "扫码消费",
        },
        {
          key: 4,
          value: "小卖部",
        },
      ],
      //类型
      selectsWithSecond: [
        {
          key: "",
          value: "类型",
        },
        {
          key: 1,
          value: "现金充值",
        },
        {
          key: 2,
          value: "批量充值",
        },
        {
          key: 3,
          value: "个别调整",
        },
        {
          key: 4,
          value: "批量调整",
        },
        {
          key: 5,
          value: "临时用餐",
        },
        {
          key: 6,
          value: "商城消费",
        },
        {
          key: 7,
          value: "余额消费",
        },
        {
          key: 8,
          value: "取消报餐",
        },
        {
          key: 9,
          value: "差错充值",
        },
        {
          key: 10,
          value: "差错调整",
        },
        {
          key: 11,
          value: "微信退款",
        },
        {
          key: 12,
          value: "微信支付",
        },
        {
          key: 13,
          value: "现场消费",
        },
      ],
    };
  },
  created () {},
  methods: {
    moment,
    search () {
      this.$emit("search", this.searchData);
    },
    reset () {
      this.timeData = [];
      this.searchData = {
        input: {},
        select: {},
        timeRange: {
          startTime: "",
          endTime: "",
        },
        selectWithSecond: {
          transaction_type_child: "",
        },
        currentDate: moment(this.getCurrentData()).format("YYYY-MM-DD"),
      };
      this.nowDate = moment(this.getCurrentData(), "YYYY-MM-DD");
      this.$emit("reset", this.searchData);
    },
    disabledDate (current) {
      if (this.timeRange.disabledBeforeNov) {
        return current && current < moment('2020-11-01');
      } else {
        return null;
      }
    },
    onChangeTimeRange (date, dateString) {
      if (this.timeRange.rangeLimit) {
        let startDate = new Date(dateString[0]).getTime(),
          endDate = new Date(dateString[1]).getTime();
        // if (startDate < 1604188800000) {
        //   this.timeData = [];
        //   this.searchData.timeRange = {
        //     startTime: "",
        //     endTime: "",
        //   };
        //   this.$message.info("请从2020年11月份开始查询");
        // }
        if (startDate + 3600 * 1000 * 24 * 30 < endDate) {
          this.timeData = [];
          this.searchData.timeRange = {
            startTime: "",
            endTime: "",
          };
          this.$message.info("日期范围最大为31天");
        } else {
          this.searchData.timeRange.startTime = dateString[0];
          this.searchData.timeRange.endTime = dateString[1];
        }
      } else {
        this.searchData.timeRange.startTime = dateString[0];
        this.searchData.timeRange.endTime = dateString[1];
      }

      // 进行范围查询时把单日查询置空
      this.searchData.currentDate = "";
      this.nowDate = "";
    },
    onChangeDateTime (date, dateString) {
      this.searchData.currentDate = dateString;
      // 进行单日查询时把范围查询置空
      this.timeData = [];
      this.searchData.timeRange.startTime = "";
      this.searchData.timeRange.endTime = "";
      this.$emit("changeDate", dateString)
    },
    getCurrentData () {
      return new Date().toLocaleDateString();
    },
    handleChange (val) {
      let type_id = this.searchData.select.type_id,
        meal_id = this.searchData.select.meal_id;
      this.$emit("select", { type_id, meal_id })
    }
  },
  computed: {},
  components: {},
  created () { },
  mounted () { },
};
</script>
<style lang='less' scoped>
</style>
